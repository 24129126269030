import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  heroSection: {
    height: '100vh',
    backgroundImage: 'url("/ban.webp")', // Assuming this is a black and gold themed image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 20px',
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.7)', // Darker overlay for stronger contrast
  },
  heroContent: {
    position: 'relative',
    zIndex: 1, // Ensure content is above overlay
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
  },
  textSection: {
    flexBasis: '55%',
    color: '#F5C892', // Light gold for text
    textAlign: 'left',
  },
  formSection: {
    flexBasis: '40%',
    backgroundColor: '#101010', // Dark black background for form
    padding: '30px',
    borderRadius: '10px',
    boxShadow: '0px 0px 15px rgba(255, 215, 0, 0.3)', // Gold-tinted shadow for emphasis
  },
  heroTitle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    color: '#F5C892', // Gold for main title
    marginBottom: '20px',
    '@media (max-width: 960px)': {
      fontSize: '2.5rem',
    },
    '@media (max-width: 600px)': {
      fontSize: '2rem',
    },
  },
  heroSubtitle: {
    marginBottom: '30px',
    fontSize: '1.2rem',
    color: '#B6A57D', // Subtle light gold for subtitle
  },
  ctaButton: {
    backgroundColor: '#C49F35', // Luxurious gold for the button
    color: '#101010', // Dark text for contrast
    padding: '10px 25px',
    '&:hover': {
      backgroundColor: '#B8860B', // Slightly deeper gold on hover
    },
  },
  formControl: {
    marginBottom: '20px',
    backgroundColor: '#1C1C1C', // Dark input background for the form
    color: '#F5C892', // Light gold for input text
    '& .MuiSelect-root': {
      color: '#F5C892 !important', // Gold color for the dropdown text
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F5C892 !important', // Gold border for the dropdown
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B8860B !important', // Slightly deeper gold on hover
    },
    '& .MuiMenuItem-root': {
      color: '#F5C892 !important', // Gold for dropdown items
    },
  },
  getResultsButton: {
    backgroundColor: '#C49F35', // Luxurious gold for form button
    color: '#101010',
    padding: '10px 25px',
    '&:hover': {
      backgroundColor: '#B8860B', // Slightly darker on hover
    },
  },
  resultBox: {
    marginTop: '20px',
    textAlign: 'center',
  },
  resultText: {
    fontSize: '1.2rem',
    color: '#F5C892', // Gold for result text
    marginBottom: '15px',
  },
  applyNowButton: {
    backgroundColor: '#C49F35',
    color: '#101010',
    padding: '10px 25px',
    '&:hover': {
      backgroundColor: '#B8860B',
    },
  },
}));

export default useStyles;
