import React from 'react';
import { Typography, Button, Box, Grid, Container, Paper } from '@mui/material';
import { CircularProgressbar } from 'react-circular-progressbar';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2'; 
import useStyles from '../styles/HomeStyles';
import HeroSection from '../components/HeroSection';
import { useNavigate } from 'react-router-dom';

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const HomeBody = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  // Mock data for charts
  const fundingData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Funding Volume ($)',
        backgroundColor: '#C49F35',
        borderColor: '#F5C892',
        borderWidth: 1,
        hoverBackgroundColor: '#F5C892',
        hoverBorderColor: '#C49F35',
        data: [12000, 19000, 30000, 50000, 20000, 30000],
      },
    ],
  };

  const industryData = {
    labels: ['Tech', 'Retail', 'Manufacturing', 'Healthcare', 'Services'],
    datasets: [
      {
        data: [30, 20, 15, 25, 10],
        backgroundColor: ['#F5C892', '#C49F35', '#808080', '#666666', '#1C1C1C'],
        hoverBackgroundColor: ['#FFF2E5', '#FFD700', '#B0B0B0', '#9C9C9C', '#2D2D2D'],
      },
    ],
  };

  const applyForm = () => {
    navigate('/apply');
  }

  return (
    <div className={classes.pageContainer}>
      {/* Hero Section */}
      <HeroSection />

      {/* Approval Process Section */}
      <div className={classes.approvalSection}>
        <Container maxWidth="lg">
          <Typography variant="h4" className={classes.heading}>How It Works</Typography>
          <Grid container spacing={4} justifyContent="center" alignItems="stretch">
            
            {/* Apply Step with Circular Progress Bar */}
            <Grid item xs={12} sm={4}>
              <Paper className={classes.approvalCard}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                  <Typography variant="h6" className={classes.stepTitle}>Apply</Typography>
                  <Box display="flex" justifyContent="center" marginBottom="20px" width="80px" height="80px">
                    <CircularProgressbar 
                      value={33} 
                      text="Step 1" 
                      styles={{
                        path: { stroke: `#C49F35` },
                        text: { fill: '#F5C892' }
                      }}
                    />
                  </Box>
                  <Typography variant="body2" className={classes.stepDescription} align="center">
                    Complete a simple form to start your application.
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Approve Step with Bar Chart */}
            <Grid item xs={12} sm={4}>
              <Paper className={classes.approvalCard}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                  <Typography variant="h6" className={classes.stepTitle}>Approve</Typography>
                  <Box display="flex" justifyContent="center" marginBottom="20px" width="100%">
                    <Bar 
                      data={{
                        labels: ['Application', 'Review', 'Approval'],
                        datasets: [
                          {
                            label: 'Progress',
                            data: [100, 80, 60],
                            backgroundColor: '#C49F35',
                            borderColor: '#F5C892',
                            borderWidth: 1
                          }
                        ]
                      }}
                      options={{ scales: { y: { beginAtZero: true } }, maintainAspectRatio: false }}
                      width={200}
                      height={150}
                    />
                  </Box>
                  <Typography variant="body2" className={classes.stepDescription} align="center">
                    We review your application and get back to you swiftly.
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Get Funded Step with Circular Progress Bar */}
            <Grid item xs={12} sm={4}>
              <Paper className={classes.approvalCard}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                  <Typography variant="h6" className={classes.stepTitle}>Get Funded</Typography>
                  <Box display="flex" justifyContent="center" marginBottom="20px" width="80px" height="80px">
                    <CircularProgressbar 
                      value={100} 
                      text="Step 3" 
                      styles={{
                        path: { stroke: `#C49F35` },
                        text: { fill: '#F5C892' }
                      }}
                    />
                  </Box>
                  <Typography variant="body2" className={classes.stepDescription} align="center">
                    Receive funding within 24 hours after approval.
                  </Typography>
                </Box>
              </Paper>
            </Grid>

          </Grid>
        </Container>
      </div>

      {/* Interactive Funding Charts Section */}
      <div className={classes.chartsSection}>
        <Container maxWidth="lg">
          <Typography variant="h3" className={classes.heading}>Business Funding Performance</Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h5" className={classes.chartTitle}>Funding Volume Over Time</Typography>
              <Bar data={fundingData} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" className={classes.chartTitle}>Industry Breakdown</Typography>
              <Doughnut data={industryData} />
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Why Choose Us Section */}
      <div className={classes.whyChooseUsSection}>
        <Container maxWidth="lg">
          <Typography variant="h3" className={classes.heading}>Why Choose Blactie Capital?</Typography>
          <Typography variant="body1" className={classes.subheading}>
            With years of expertise in business funding, we help companies of all sizes achieve their financial goals.
          </Typography>
        </Container>
      </div>

      {/* Testimonials Section */}
      <div className={classes.testimonialsSection}>
        <Container maxWidth="lg">
          <Typography variant="h4" className={classes.heading}>What Our Clients Say</Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Paper className={classes.testimonialCard}>
                <Typography variant="body2" className={classes.quote}>
                  "Blactie Capital made funding our business seamless. Their professionalism and efficiency were top-notch!"
                </Typography>
                <Typography variant="caption" className={classes.clientName}>
                  - John D., Business Owner
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.testimonialCard}>
                <Typography variant="body2" className={classes.quote}>
                  "The team at Blactie Capital helped us expand faster than we thought possible. Highly recommended!"
                </Typography>
                <Typography variant="caption" className={classes.clientName}>
                  - Sarah K., CEO
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.testimonialCard}>
                <Typography variant="body2" className={classes.quote}>
                  "I was skeptical about online funding, but Blactie Capital proved me wrong! In less than 48 hours, we had everything we needed."
                </Typography>
                <Typography variant="caption" className={classes.clientName}>
                  - Michael B., Tech Startup Founder
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.testimonialCard}>
                <Typography variant="body2" className={classes.quote}>
                  "Blactie Capital gave us the financial backing to open three new locations and hire over 20 employees. We couldn’t be happier."
                </Typography>
                <Typography variant="caption" className={classes.clientName}>
                  - Amanda R., Retail Store Owner
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.testimonialCard}>
                <Typography variant="body2" className={classes.quote}>
                  "Their team is knowledgeable and extremely helpful. We secured funding that we couldn't have imagined from traditional banks."
                </Typography>
                <Typography variant="caption" className={classes.clientName}>
                  - David M., Manufacturing Business Owner
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.testimonialCard}>
                <Typography variant="body2" className={classes.quote}>
                  "We expanded our healthcare services with Blactie Capital’s fast and easy funding process. Highly recommend!"
                </Typography>
                <Typography variant="caption" className={classes.clientName}>
                  - Christine L., Healthcare Service Director
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* Optional Call to Action Below Testimonials */}
          <Box textAlign="center" mt={4}>
            <Typography variant="h5" className={classes.ctaHeading}>Ready to see how we can help your business grow?</Typography>
            <Button variant="contained" className={classes.ctaButton}>Get in Touch</Button>
          </Box>
        </Container>
      </div>

      {/* Call to Action Section */}
      <div className={classes.ctaSection}>
        <Container maxWidth="md">
          <Typography variant="h5" className={classes.ctaHeading}>Ready to Get Started?</Typography>
          <Button variant="contained" className={classes.ctaButton} onClick={applyForm}>
            Apply Now
          </Button>
        </Container>
      </div>
    </div>
  );
};

export default HomeBody;

