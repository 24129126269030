import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: '20px',
    backgroundColor: '#101010', // Dark background
    borderRadius: '8px',
    boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3)', // Gold shadow
    color: '#F5C892', // Gold text color
    marginTop: '20px',
    marginBottom: '20px',
    '@media (min-width:600px)': {
      padding: '40px',
      marginTop: '40px',
      marginBottom: '40px',
    },
  },
  mainTitle: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#F5C892', // Luxurious gold title
    fontSize: '2rem',
    textAlign: 'center',
    '@media (min-width:600px)': {
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
  },
  description: {
    color: '#B6A57D',
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '20px',
    '@media (min-width:600px)': {
      fontSize: '1.2rem',
      marginBottom: '30px',
    },
  },
  sectionTitle: {
    margin: '20px 0 10px',
    fontWeight: 'bold',
    color: '#F5C892', // Gold title color
    fontSize: '1.5rem',
    textAlign: 'center',
    '@media (min-width:600px)': {
      margin: '40px 0 20px',
      fontSize: '1.8rem',
    },
  },
  formField: {
    marginBottom: '15px',
    '& label': {
      color: '#B6A57D', // Subtle gold text for label
    },
    '& label.Mui-focused': {
      color: '#F5C892', // Gold text when focused
    },
    '& input': {
      color: '#F5C892', // Gold text for input
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B6A57D', // Subtle gold border
      },
      '&:hover fieldset': {
        borderColor: '#F5C892', // Gold on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F5C892', // Gold when focused
      },
    },
    '& .MuiSelect-icon': {
      color: '#F5C892', // Gold for dropdown arrow
    },
    '@media (min-width:600px)': {
      marginBottom: '20px',
    },
  },
  captchaBox: {
    backgroundColor: '#1C1C1C', // Darker background for the CAPTCHA box
    padding: '10px',
    borderRadius: '8px',
    marginTop: '20px',
    textAlign: 'center',
    boxShadow: '0px 0px 10px rgba(255, 215, 0, 0.2)', // Subtle gold glow
    '@media (min-width:600px)': {
      padding: '15px',
    },
  },
  captchaText: {
    color: '#F5C892',
    fontSize: '1rem',
    marginBottom: '10px',
    '@media (min-width:600px)': {
      fontSize: '1.2rem',
    },
  },
  captchaInput: {
    maxWidth: '120px',
    margin: '0 auto',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B6A57D', // Subtle border
      },
      '&:hover fieldset': {
        borderColor: '#F5C892', // Gold on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F5C892', // Gold when focused
      },
    },
    '& input': {
      color: '#F5C892', // Gold text for input
    },
    '@media (min-width:600px)': {
      maxWidth: '150px',
    },
  },
  errorMessage: {
    color: '#FF6B6B', // Red color for error messages
    marginTop: '10px',
  },
  submitButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#C49F35 !important', // Luxurious gold for the button
    color: '#101010', // Dark text for contrast
    fontSize: '1rem',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#B8860B', // Slightly deeper gold on hover
    },
    '&:disabled': {
      backgroundColor: '#555', // Disabled button color
      color: '#aaa',
    },
    '@media (min-width:600px)': {
      marginTop: '30px',
      padding: '15px 30px',
      fontSize: '1.2rem',
    },
  },
  signatureCanvas: {
    border: '2px solid #F5C892', // Gold border for the signature canvas
    borderRadius: '4px',
    marginBottom: '20px',
  },
  signatureActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    '& button': {
      marginTop: '10px',
    },
    '@media (min-width:600px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  signatureButton: {
    marginTop: '10px',
    color: '#F5C892',
    borderColor: '#F5C892',
    '&:hover': {
      borderColor: '#B8860B',
    },
  },
}));

export default useStyles;
