import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  uploadContainer: {
    backgroundColor: '#121212', // Dark background
    padding: '40px 20px',
    color: '#D4AF37', // Luxurious gold color for text
    borderRadius: '10px',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#FFD700', // Bright gold for the title
    marginBottom: '20px',
  },
  description: {
    fontSize: '1.2rem',
    marginBottom: '30px',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#FFFFFF', // White for section titles
    marginBottom: '15px',
  },
  uploadButton: {
    backgroundColor: '#FFD700', // Bright gold for buttons
    color: '#121212', // Dark text for contrast
    fontWeight: 'bold',
    padding: '10px 30px',
    '&:hover': {
      backgroundColor: '#B8860B', // Darker gold on hover
    },
  },
  successMessage: {
    color: '#D4AF37', // Gold for success messages
    marginTop: '10px',
  },
  errorMessage: {
    color: '#FF4C4C', // Red for error messages
    marginTop: '10px',
  },
  submitButton: {
    marginTop: '30px',
    backgroundColor: '#FFD700', // Bright gold for the submit button
    color: '#121212',
    fontWeight: 'bold',
    padding: '10px 40px',
    '&:hover': {
      backgroundColor: '#B8860B',
    },
  },
}));

export default useStyles;
