import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyC5A3Iqz1ouDsI6lKg3-ilmgOf6cuCsBCU",
    authDomain: "blactie-capital.firebaseapp.com",
    databaseURL: "https://blactie-capital-default-rtdb.firebaseio.com",
    projectId: "blactie-capital",
    storageBucket: "blactie-capital.appspot.com",
    messagingSenderId: "495535704452",
    appId: "1:495535704452:web:cc4fa554bb5f94febbb5d1",
    measurementId: "G-WPWM18B028"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, firebaseConfig }; // Export both database and firebaseConfig
