import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#000', // Black background for a sleek appearance
    padding: '20px 0',
    textAlign: 'center',
    borderTop: '2px solid #B8860B', // A luxurious gold border at the top
  },
  footerContent: {
    color: '#D4AF37', // Gold text color to match the luxury theme
    fontFamily: 'Serif, sans-serif', // Elegant font
  },
  footerText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  subText: {
    marginTop: '10px',
    fontSize: '1rem',
  },
  footerLink: {
    color: '#FFD700', // Bright gold for the link
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
