// src/Login.jsx



import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'; // Adjust the path as needed

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await login(email, password);
      navigate('/admin');
    } catch (error) {
      alert("Login failed. Please check your credentials and try again.");
    }
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: '2rem' }}>
      <Paper
        elevation={3}
        style={{
          padding: '2rem',
          backgroundColor: '#0D0D0D', // Consistent dark background
          color: '#F5C892', // Gold for text
          borderRadius: '15px',
          boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3)', // Gold shadow for emphasis
        }}
      >
        <Box textAlign="center" marginBottom="1rem">
          <Typography variant="h5" style={{ color: '#C49F35', fontWeight: 'bold' }}>
            Login
          </Typography>
        </Box>
        <form noValidate autoComplete="off">
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            InputLabelProps={{ style: { color: '#B6A57D' } }} // Subtle gold for labels
            InputProps={{
              style: { color: '#FFFFFF' },
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#C49F35',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#F5C892',
                },
              },
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            margin="normal"
            InputLabelProps={{ style: { color: '#B6A57D' } }}
            InputProps={{
              style: { color: '#FFFFFF' },
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#C49F35',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#F5C892',
                },
              },
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box marginTop="1.5rem">
            <Button
              fullWidth
              variant="contained"
              style={{
                backgroundColor: '#C49F35', // Luxurious gold for button
                color: '#101010', // Dark text for contrast
                fontWeight: 'bold',
                boxShadow: '0px 0px 15px rgba(255, 215, 0, 0.4)', // Gold shadow
              }}
              sx={{
                '&:hover': {
                  backgroundColor: '#B8860B', // Deeper gold on hover
                  boxShadow: '0px 0px 25px rgba(255, 215, 0, 0.5)',
                },
              }}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
