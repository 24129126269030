import React from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useApplications from '../hooks/useApplications'; // Custom hook
import useStyles from '../styles/AdminDashboardStyles';

const AdminDashboard = () => {
  const classes = useStyles();
  const { applications, loading, error } = useApplications(); // Destructure loading, error, and applications

  // Generate and download PDF for each application
  const downloadPDF = (application) => {
    const doc = new jsPDF();
    const logo = "/blogo.png"; // Path to your logo

    const pageHeight = doc.internal.pageSize.height; // Get page height
    let currentY = 10; // Initialize current Y position for content

    // Add top banner with logo and company contact information
    doc.addImage(logo, "PNG", 20, currentY, 30, 30); // Adjust logo size and position
    doc.setFontSize(10);
    doc.text("BlacTie Capital", 140, currentY + 5);
    doc.text("160 W. Camino Real, Unit #977", 140, currentY + 10);
    doc.text("Boca Raton, FL 33432", 140, currentY + 15);
    doc.text("Phone: (555) 555-5555", 140, currentY + 20);
    doc.text("Fax: (555) 555-5556", 140, currentY + 25);
    doc.text("Email: info@blactiecapital.com", 140, currentY + 30);

    // Divider line below banner
    currentY += 40; // Update Y position
    doc.setDrawColor(0, 0, 0);
    doc.line(10, currentY, 200, currentY); // Horizontal line to divide sections

    // Add submission date
    currentY += 10;
    doc.setFontSize(12);
    doc.text(
      `Date Submitted: ${new Date(
        application.dateSubmitted
      ).toLocaleDateString()}`,
      10,
      currentY
    );

    // Instruction section
    currentY += 10;
    doc.setFontSize(12);
    doc.text(
      "Please fill in the spaces below and mail or fax us the application. By doing so, you are giving BlacTie Capital, as well as its agents and affiliates, permission to review your business and personal credit history in order to provide you with formal approval.",
      10,
      currentY,
      { maxWidth: 190 }
    );

    currentY += 20;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }
    // Business Information Section
    doc.setFontSize(14);
    doc.text("Part 1: Business Information", 10, currentY);
    currentY += 10;
    doc.autoTable({
      startY: currentY,
      head: [
        [
          "Business Legal Name",
          "Products/Services Sold",
          "Business DBA Name",
          "Date Business Started",
          "Annual Revenue",
        ],
      ],
      body: [
        [
          application.businessName,
          application.productsSold,
          application.dbaName,
          application.dateBusinessStarted,
          `$${application.annualRevenue}`,
        ],
      ],
    });

    currentY = doc.previousAutoTable.finalY + 10;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }

    doc.autoTable({
      startY: currentY,
      head: [
        ["Business Address", "Federal Tax #", "City/Zip/State", "Legal Entity"],
      ],
      body: [
        [
          application.businessAddress,
          application.federalTaxID,
          application.cityStateZip,
          application.legalEntity,
        ],
      ],
    });

    currentY = doc.previousAutoTable.finalY + 10;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }

    doc.autoTable({
      startY: currentY,
      head: [
        [
          "Business Phone",
          "Location Type",
          "Mobile Phone",
          "Avg Gross Monthly Sales",
        ],
      ],
      body: [
        [
          application.businessPhone,
          application.locationType,
          application.mobilePhone,
          application.averageGrossSales,
        ],
      ],
    });

    currentY = doc.previousAutoTable.finalY + 10;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }

    doc.autoTable({
      startY: currentY,
      head: [
        [
          "Fax",
          "Average Monthly Credit Card Sales",
          "Email Address",
          "Rent or Own Business Property",
        ],
      ],
      body: [["(555) 555-5559", "$5,000", application.ownerEmail, "Rent"]],
    });

    currentY = doc.previousAutoTable.finalY + 10;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }

    // Owner Information Section
    doc.text("Part 2: Owner Information", 10, currentY);
    currentY += 10;
    doc.autoTable({
      startY: currentY,
      head: [["Owner Name", "Address", "City/State/Zip", "Phone"]],
      body: [
        [
          application.ownerName,
          application.ownerAddress,
          application.ownerCityStateZip,
          application.ownerPhone,
        ],
      ],
    });

    currentY = doc.previousAutoTable.finalY + 10;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }

    doc.autoTable({
      startY: currentY,
      head: [["Email", "% of Ownership", "Date of Birth", "SSN"]],
      body: [
        [
          application.ownerEmail,
          application.ownershipPercentage,
          application.ownerDOB,
          application.ownerSSN,
        ],
      ],
    });

    currentY = doc.previousAutoTable.finalY + 10;
    if (currentY >= pageHeight - 30) {
      doc.addPage();
      currentY = 20;
    }

    // Funding Information Section
    // doc.text("Part 3: Funding Information", 10, currentY);
    // currentY += 10;
    // doc.autoTable({
    //   startY: currentY,
    //   head: [
    //     [
    //       "Amount Requested",
    //       "Previous Cash Advance?",
    //       "Date of Funding",
    //       "Balance Due",
    //     ],
    //   ],
    //   body: [
    //     [
    //       application.amountRequested,
    //       application.previousCashAdvance,
    //       application.dateOfFunding,
    //       application.balanceDue,
    //     ],
    //   ],
    // });

    // currentY = doc.previousAutoTable.finalY + 10;
    // if (currentY >= pageHeight - 30) {
    //   doc.addPage();
    //   currentY = 20;
    // }

    // Authorization Section (Adjusted for proper width wrapping)
    doc.setFontSize(10); // Ensure smaller text to fit properly
    currentY += 10;
    doc.text(
      "By signing below, the Merchant and its owners / principals:",
      10,
      currentY,
      { maxWidth: 190 } // Wrap text to fit within page width
    );
    currentY += 10;
    doc.text(
      "(1) certify that all information and documents submitted in connection with this Application is true, correct and complete;",
      10,
      currentY,
      { maxWidth: 190 }
    );
    currentY += 10;
    doc.text(
      "(2) authorize BlacTie Capital, partners, and lenders to receive credit reports and any other information regarding the Merchant",
      10,
      currentY,
      { maxWidth: 190 }
    );
    currentY += 10;
    doc.text(
      "and its owners and principals from third parties, to verify any information provided on the Application.",
      10,
      currentY,
      { maxWidth: 190 }
    );

    // Signature Section
    currentY += 20;

    if (application.signature) {
      doc.addImage(application.signature, "PNG", 10, currentY, 50, 20); // Adjust the width and height as needed
    }

    currentY += 30;
    doc.text("__________________________________", 10, currentY); // Signature line for Owner 1
    doc.text("Owner 1", 10, currentY + 5);

    doc.text("__________________________________", 100, currentY); // Signature line for Owner 2
    doc.text("Owner 2", 100, currentY + 5);

    // Save the PDF
    doc.save(`${application.businessName}_Application.pdf`);
  };

  if (loading) return <Typography>Loading applications...</Typography>;
  if (error)
    return <Typography>Error loading applications: {error}</Typography>;

  return (
    <Container maxWidth="lg" className={classes.dashboardContainer}>
      <Typography variant="h4" className={classes.mainTitle}>
        Submitted Applications
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Business Name</TableCell>
              <TableCell>Owner Name</TableCell>
              <TableCell>Date Submitted</TableCell>
              <TableCell>Amount Requested</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((application) => (
              <TableRow key={application.id}>
                <TableCell>{application.businessName}</TableCell>
                <TableCell>{application.ownerName}</TableCell>
                <TableCell>
                  {application.dateSubmitted
                    ? new Date(application.dateSubmitted).toLocaleDateString()
                    : "N/A"}
                </TableCell>

                <TableCell>{application.amountRequested}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadPDF(application)}
                    className={classes.downloadButton}
                  >
                    Download PDF
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminDashboard;