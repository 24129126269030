import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    'html, body': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      backgroundColor: '#0D0D0D', // Ensure the body background matches your theme
    },
    '*': {
      boxSizing: 'inherit',
    },
  },
  pageContainer: {
    backgroundColor: '#0D0D0D', // Black background for entire page
    color: '#F5C892 !important', // Gold as primary text color
  },
  heading: {
    color: '#F5C892 !important', // Gold for headings
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '40px',
    textTransform: 'uppercase',
  },
  subheading: {
    color: '#B6A57D !important', // Subtle gold for subheadings
    textAlign: 'center',
    marginBottom: '20px',
  },
  approvalSection: {
    padding: '80px 0',
    backgroundColor: '#101010 !important', // Slightly lighter black for contrast
  },
  approvalCard: {
    padding: '30px',
    backgroundColor: '#1C1C1C !important', // Black for card background
    color: '#F5C892 !important', // Gold for text
    borderRadius: '15px',
    textAlign: 'center',
    boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3) !important', // Gold shadow for emphasis
    '&:hover': {
      boxShadow: '0px 0px 25px rgba(255, 215, 0, 0.5) !important',
      transform: 'scale(1.05)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
  },
  stepTitle: {
    fontSize: '1.5rem !important',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  stepDescription: {
    fontSize: '1rem !important',
  },
  whyChooseUsSection: {
    padding: '80px 0',
    backgroundColor: '#0D0D0D !important',
  },
  testimonialsSection: {
    padding: '80px 0',
    backgroundColor: '#101010 !important', // Consistent dark background
  },
  testimonialCard: {
    padding: '30px',
    backgroundColor: '#1C1C1C !important', // Black for card background
    borderRadius: '15px',
    color: '#F5C892 !important', // Gold for text
    boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3) !important', // Gold shadow for emphasis
    textAlign: 'center',
    '&:hover': {
      boxShadow: '0px 0px 25px rgba(255, 215, 0, 0.5) !important', // Gold shadow on hover
      transform: 'scale(1.05)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
  },
  quote: {
    fontSize: '1.1rem !important',
    fontStyle: 'italic',
    marginBottom: '15px',
  },
  clientName: {
    fontSize: '0.9rem !important',
    color: '#B6A57D !important',
  },
  ctaSection: {
    padding: '60px 0',
    backgroundColor: '#0D0D0D !important', // Consistent dark background
    textAlign: 'center',
  },
  ctaButton: {
    backgroundColor: '#C49F35 !important', // Luxurious gold for button
    color: '#101010 !important', // Dark text for contrast
    padding: '15px 40px !important',
    fontSize: '1.2rem !important',
    '&:hover': {
      backgroundColor: '#B8860B !important', // Slightly deeper gold on hover
      boxShadow: '0px 0px 15px rgba(255, 215, 0, 0.4) !important',
    },
  },
  chartsSection: {
    padding: '80px 0',
    backgroundColor: '#0D0D0D !important',
  },
  chartTitle: {
    color: '#F5C892 !important', // Gold for chart titles
    marginBottom: '20px',
    textAlign: 'center',
  },
  caseStudySection: {
    padding: '80px 0',
    backgroundColor: '#0D0D0D !important',
    color: '#F5C892 !important', // Gold for text
  },
  caseStudyCard: {
    backgroundColor: '#1C1C1C !important', // Black for card background
    padding: '20px',
    borderRadius: '10px',
    color: '#F5C892 !important', // Gold for text
    boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3) !important', // Gold shadow for emphasis
    '&:hover': {
      boxShadow: '0px 0px 25px rgba(255, 215, 0, 0.5) !important', // Hover effect for cards
      transform: 'scale(1.05)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
  },
  caseStudyTitle: {
    fontSize: '1.5rem !important',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  caseStudyDescription: {
    fontSize: '1rem !important',
    marginBottom: '20px',
  },
  caseStudyStats: {
    fontSize: '0.9rem !important',
    color: '#B6A57D !important',
  },
  caseStudyImage: {
    background: 'url("/casestudy.webp") center center / cover no-repeat',
    height: '300px',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3) !important',
  },
}));

export default useStyles;

