import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import useStyles from '../styles/FooterStyles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Box className={classes.footerContent}>
          <Typography variant="body1" className={classes.footerText}>
            © 2024 Bactie Capital LLC
          </Typography>
          <Typography variant="body2" className={classes.subText}>
            Registered in the State of New Mexico, USA | For more information, visit <a href="https://blacktie.com" className={classes.footerLink}>blacktie.com</a>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
