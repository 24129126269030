import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dashboardContainer: {
    backgroundColor: '#101010', // Dark background
    padding: '40px',
    borderRadius: '8px',
    color: '#F5C892', // Gold text color
    marginTop: '40px',
    marginBottom: '40px',
  },
  mainTitle: {
    marginBottom: '20px',
    fontWeight: 'bold',
    color: '#F5C892', // Gold title
    fontSize: '2.5rem',
    textAlign: 'center',
  },
  downloadButton: {
    backgroundColor: '#C49F35', // Luxurious gold button
    color: '#101010',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#B8860B', // Slightly deeper gold on hover
    },
  },
}));

export default useStyles;
