import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

const BusinessInformationForm = ({ classes, formData, handleInputChange }) => (
  <>
    <Typography variant="h5" className={classes.sectionTitle}>
      Business Information
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField 
          label="Company Name" 
          name="companyName" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.companyName} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField 
          label="Federal Tax ID (EIN)" 
          name="federalTaxId" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.federalTaxId} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12}>
        <TextField 
          label="Business Address" 
          name="businessAddress" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.businessAddress} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField 
          label="City" 
          name="businessCity" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.businessCity} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField 
          label="State" 
          name="businessState" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.businessState} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField 
          label="Zip Code" 
          name="businessZipCode" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.businessZipCode} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField 
          label="Business Phone Number" 
          name="businessPhoneNumber" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.businessPhoneNumber} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField 
          label="Annual Revenue" 
          name="annualRevenue" // Added name attribute
          variant="outlined" 
          fullWidth 
          required 
          className={classes.formField} 
          value={formData.annualRevenue} // Ensure formData is passed
          onChange={handleInputChange} 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Date Business Started"
          name="businessStartDate" // Added name attribute
          variant="outlined"
          fullWidth
          required
          type="date"
          InputLabelProps={{ shrink: true }}
          className={classes.formField}
          value={formData.businessStartDate} // Ensure formData is passed
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth className={classes.formField}>
          <InputLabel>Business Type</InputLabel>
          <Select
            name="businessType" // Added name attribute
            value={formData.businessType} // Ensure formData is passed
            onChange={handleInputChange}
          >
            <MenuItem value="">
              <em>Select One</em>
            </MenuItem>
            <MenuItem value="LLC">LLC</MenuItem>
            <MenuItem value="Corporation">Corporation</MenuItem>
            <MenuItem value="Sole Proprietorship">Sole Proprietorship</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  </>
);

export default BusinessInformationForm;
