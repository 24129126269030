import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import useStyles from '../styles/HeaderStyles';
import { useNavigate } from 'react-router-dom';


const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const applyForm = () => {
    navigate('/apply');
  }

  const aboutUs = () => {
    navigate('/about');
  }

  const home = () => {
    navigate('/');
  }
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {/* Logo Section */}
        <img src="/logo.png" alt="Blactie Capital Logo" className={classes.logo} />

        {/* Navigation Buttons */}
        <div className={classes.navButtons}>
          <Button className={classes.button} onClick={home}>Home</Button>
          <Button className={classes.button} onClick={aboutUs}>About Us</Button>
          <Button className={classes.button} onClick={applyForm}>Apply Now</Button>
          <Button className={classes.button}>Contact</Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

