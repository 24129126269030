import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

const PersonalInformationForm = ({ classes, formData, handleInputChange }) => (
  <>
    <Typography variant="h5" className={classes.sectionTitle}>
      Personal Information
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="First Name"
          name="firstName"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.firstName || ''} // Ensure non-null value
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Last Name"
          name="lastName"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.lastName || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Address"
          name="address"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.address || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="City"
          name="city"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.city || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label="State"
          name="state"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.state || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label="Zip Code"
          name="zipCode"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.zipCode || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email Address"
          name="email"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.email || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Phone Number"
          name="phoneNumber"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.phoneNumber || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Social Security Number (SSN)"
          name="ssn"
          variant="outlined"
          fullWidth
          required
          type="password"
          className={classes.formField}
          value={formData.ssn || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Date of Birth"
          name="dateOfBirth"
          variant="outlined"
          fullWidth
          required
          type="date"
          InputLabelProps={{ shrink: true }}
          className={classes.formField}
          value={formData.dateOfBirth || ''}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Job Title"
          name="jobTitle"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formData.jobTitle || ''}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  </>
);

export default PersonalInformationForm;
