import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.8) !important', // Set a transparent black background to blend with the banner
    padding: '10px 0 !important',
    boxShadow: 'none !important', // Remove any shadow for a seamless blend
    position: 'absolute', // Ensure the header sits on top of the banner
    top: 0,
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    height: '50px', // Adjust the height of the logo
    objectFit: 'contain', // Ensures the logo maintains its aspect ratio
  },
  navButtons: {
    display: 'flex',
    gap: '20px',
  },
  button: {
    color: '#F5C892 !important', // Luxurious light gold for buttons
    fontSize: '1rem !important',
    fontWeight: 'bold !important',
    fontFamily: '"Orbitron", sans-serif !important',
    textTransform: 'uppercase !important',
    padding: '10px 20px !important',
    borderRadius: '8px !important',
    transition: 'all 0.3s ease-in-out !important',
    background: 'transparent !important',
    border: '2px solid transparent !important',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3) !important', // Slight black background on hover
      borderColor: '#C49F35 !important', // Gold border on hover
      boxShadow: '0 0 10px #C49F35, 0 0 20px #C49F35 !important', // Gold glow shadow on hover
    },
  },
}));

export default useStyles;
