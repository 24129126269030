import React, { useState } from 'react';
import { Container, Typography, Button, Grid } from '@mui/material';
import useStyles from '../styles/UploadDocumentsStyles';

const UploadDocuments = () => {
  const classes = useStyles();
  const [idDocument, setIdDocument] = useState(null);
  const [statements, setStatements] = useState([]);
  const [uploadError, setUploadError] = useState('');

  // Handle ID Document Upload
  const handleIdUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setIdDocument(file);
      setUploadError('');
    } else {
      setUploadError('Please upload a valid PDF document.');
      setIdDocument(null);
    }
  };

  // Handle Bank Statements Upload
  const handleStatementsUpload = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => file.type === 'application/pdf');

    if (validFiles.length !== 3) {
      setUploadError('Please upload exactly 3 PDF files.');
      setStatements([]);
    } else {
      setUploadError('');
      setStatements(validFiles);
    }
  };

  return (
    <Container maxWidth="md" className={classes.uploadContainer}>
      <Typography variant="h4" className={classes.title}>
        Upload Documents
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Please upload your ID document and the last 3 months of bank statements to continue your application.
      </Typography>

      {/* ID Document Upload Section */}
      <Typography variant="h5" className={classes.sectionTitle}>
        Upload ID Document (PDF)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" component="label" className={classes.uploadButton}>
            Upload ID Document
            <input type="file" accept=".pdf" hidden onChange={handleIdUpload} />
          </Button>
          {idDocument && (
            <Typography variant="body2" className={classes.successMessage}>
              ID Document uploaded: {idDocument.name}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Bank Statements Upload Section */}
      <Typography variant="h5" className={classes.sectionTitle}>
        Upload Bank Statements (Past 3 Months)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" component="label" className={classes.uploadButton}>
            Upload Bank Statements (PDF)
            <input type="file" accept=".pdf" hidden multiple onChange={handleStatementsUpload} />
          </Button>
          {uploadError && (
            <Typography variant="caption" className={classes.errorMessage}>
              {uploadError}
            </Typography>
          )}
          {statements.length > 0 && (
            <Typography variant="body2" className={classes.successMessage}>
              {statements.length} files uploaded successfully.
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Button
        variant="contained"
        className={classes.submitButton}
        disabled={!idDocument || statements.length !== 3}
      >
        Submit Documents
      </Button>
    </Container>
  );
};

export default UploadDocuments;
