import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leads: [
    { name: 'John Doe', phone: '555-1234', email: 'john@example.com', businessName: "John's Bakery", status: 'New' },
    { name: 'Jane Smith', phone: '555-5678', email: 'jane@example.com', businessName: "Jane's Consulting", status: 'New' },
    { name: 'Robert Johnson', phone: '555-8765', email: 'robert.johnson@example.com', businessName: 'Johnson & Co.', status: 'New' },
    { name: 'Emily Davis', phone: '555-4321', email: 'emily.davis@example.com', businessName: 'Davis Solutions', status: 'New' },
  ],
  revealedLeads: [],
  filterStatus: 'All',
  loading: false,
  error: null,
};

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    revealLead: (state) => {
      if (state.leads.length > 0) {
        const nextLead = state.leads.shift(); // Remove the first lead
        state.revealedLeads.push({ ...nextLead }); // Add to revealed leads
      }
    },
    updateLeadStatus: (state, action) => {
      const { lead, newStatus } = action.payload;
      const leadIndex = state.revealedLeads.findIndex((l) => l.email === lead.email); // Find by unique identifier (email in this case)
      if (leadIndex >= 0) {
        state.revealedLeads[leadIndex].status = newStatus; // Update the status immutably
      }
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
  },
});

export const { revealLead, updateLeadStatus, setFilterStatus } = leadsSlice.actions;

export default leadsSlice.reducer;
