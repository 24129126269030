import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Container,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import useStyles from "../styles/BannerStyles";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const HeroSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State for form inputs and result
  const [timeInBusiness, setTimeInBusiness] = useState("");
  const [monthlyRevenue, setMonthlyRevenue] = useState("");
  const [creditScore, setCreditScore] = useState("");
  const [calculatedCapital, setCalculatedCapital] = useState("");
  const navigate = useNavigate();

  // Formula to calculate potential business funding
  const calculateFunding = () => {
    let baseAmount = 10000; // Base funding amount

    // Adjust funding based on time in business
    if (timeInBusiness === 2) {
      baseAmount += 15000;
    } else if (timeInBusiness === 3) {
      baseAmount += 30000;
    }

    // Adjust funding based on monthly revenue
    if (monthlyRevenue === 2) {
      baseAmount += 25000;
    } else if (monthlyRevenue === 3) {
      baseAmount += 50000;
    }

    // Adjust funding based on credit score
    if (creditScore === 2) {
      baseAmount += 10000;
    } else if (creditScore === 3) {
      baseAmount += 20000;
    }

    setCalculatedCapital(
      `You may be eligible for up to $${baseAmount} in funding.`
    );
  };

  const applyForm = () => {
    navigate("/apply");
  };

  return (
    <div className={classes.heroSection}>
      <div className={classes.heroOverlay} />
      <Container className={classes.heroContent}>
        {/* Left Text Section */}
        <Box className={classes.textSection}>
          <Typography variant="h3" className={classes.heroTitle}>
            Funding for Your Vision
          </Typography>
          <Typography variant="h6" className={classes.heroSubtitle}>
            Fast, flexible funding solutions tailored for startups and
            established enterprises.
          </Typography>
          <Button
            variant="contained"
            className={classes.ctaButton}
            onClick={applyForm}
          >
            Start Your Application
          </Button>
        </Box>

        {/* Right Form Section */}
        {!isSmallScreen && (
          <Box className={classes.formSection}>
            <Typography variant="h3" className={classes.bannerTitle}>
              Discover Your Funding Potential
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  value={timeInBusiness}
                  onChange={(e) => setTimeInBusiness(e.target.value)}
                  className={classes.formControl}
                >
                  <MenuItem value="" disabled>
                    Time in Business
                  </MenuItem>
                  <MenuItem value={1}>0-1 years</MenuItem>
                  <MenuItem value={2}>1-3 years</MenuItem>
                  <MenuItem value={3}>3+ years</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  value={monthlyRevenue}
                  onChange={(e) => setMonthlyRevenue(e.target.value)}
                  className={classes.formControl}
                >
                  <MenuItem value="" disabled>
                    Average Monthly Revenue
                  </MenuItem>
                  <MenuItem value={1}>$0 - $10k</MenuItem>
                  <MenuItem value={2}>$10k - $50k</MenuItem>
                  <MenuItem value={3}>$50k+</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  value={creditScore}
                  onChange={(e) => setCreditScore(e.target.value)}
                  className={classes.formControl}
                >
                  <MenuItem value="" disabled>
                    Credit Score
                  </MenuItem>
                  <MenuItem value={1}>Below 600</MenuItem>
                  <MenuItem value={2}>600 - 700</MenuItem>
                  <MenuItem value={3}>Above 700</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.getResultsButton}
                  onClick={calculateFunding}
                >
                  View Results
                </Button>
              </Grid>
            </Grid>

            {/* Dynamic area that shows the calculated funding and apply button */}
            {calculatedCapital && (
              <Box className={classes.resultBox}>
                <Typography className={classes.resultText}>
                  {calculatedCapital}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.applyNowButton}
                  onClick={applyForm}
                >
                  Apply Now
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </div>
  );
};

export default HeroSection;
