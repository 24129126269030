import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import useStyles from '../styles/AboutUsStyles';
import Footer from '../components/Footer';
import Header from '../components/header';

const AboutUs = () => {
  const classes = useStyles();

  return (
    <div>
      <Header />
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box className={classes.headerBox}>
          <Typography variant="h2" className={classes.headerText}>
            About BlacTie Capital
          </Typography>
        </Box>

        <Box className={classes.missionBox}>
          <Typography variant="h5" className={classes.subtitle}>
            Our Mission
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            At BlacTie Capital, our mission is to provide a reliable, secure, and fast way for entrepreneurs and business owners to access funds and grow their businesses. Since 2009, we’ve been committed to empowering businesses across the United States by offering tailored financing solutions that help companies capitalize on timely opportunities.
          </Typography>
        </Box>

        <Box className={classes.commitmentBox}>
          <Typography variant="h5" className={classes.subtitle}>
            Our Commitment to Business Growth
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            With an experienced team of financial professionals, BlacTie Capital has collectively financed thousands of businesses across a diverse range of industries. Our clients have benefited from hundreds of millions of dollars in funding, helping them seize growth opportunities without the traditional red tape associated with bank loans.
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            We pride ourselves on a faster, simpler loan experience, providing flexible funding options when others cannot. This dedication has made us a recognized leader in business financing, earning us the trust of countless businesses and recognition by the Better Business Bureau.
          </Typography>
        </Box>

        <Box className={classes.valuesBox}>
          <Typography variant="h5" className={classes.subtitle}>
            Our Core Values
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            BlacTie Capital is built on integrity, transparency, and excellence. These values drive every decision we make, ensuring that we serve our clients with the utmost professionalism and deliver results that exceed expectations.
          </Typography>
        </Box>

        <Box className={classes.contactBox}>
          <Typography variant="h5" className={classes.subtitle}>
            Contact Us Today
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            If you're ready to grow your business, contact BlacTie Capital today. Our team of financial experts is here to provide the solutions you need to take your business to the next level.
          </Typography>
          <Button variant="contained" className={classes.contactButton}>
            Get Started
          </Button>
        </Box>
      </Container>

      <Footer />
    </div>
    </div>
  );
};

export default AboutUs;
