// src/App.jsx

// src/App.jsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider, useAuth } from './hooks/useAuth';
import ApplicationForm from './components/ApplicationForm';
// import Dashboard from './pages/Dashboard';
import AboutUs from './pages/About';
import UploadDocuments from './components/UploadDocuments';
import AdminDashboard from './pages/AdminDashboard';
import Login from './components/Login';

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" replace />;
}

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/apply" element={<ApplicationForm />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/upload" element={<UploadDocuments />} />
            <Route path="/login" element={<Login />} />

            {/* Protect the /admin route */}
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
