import React from 'react';
import { Typography, Button, Box, Grid, Container, Paper } from '@mui/material';
import useStyles from '../styles/HomeStyles';

const CaseStudySection = () => {
  const classes = useStyles();

  return (
    <div className={classes.caseStudySection}>
      <Container maxWidth="lg">
        <Typography variant="h3" className={classes.heading}>Our Proven Success</Typography>
        <Typography variant="body1" className={classes.subheading}>
          Blactie Capital has been pivotal in helping companies across various industries achieve their funding goals and grow their businesses. Explore one of our most successful case studies below:
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.caseStudyCard}>
              <Typography variant="h6" className={classes.caseStudyTitle}>
                Case Study: Tech Startup Expansion
              </Typography>
              <Typography variant="body2" className={classes.caseStudyDescription}>
                A San Francisco-based tech startup approached us in need of $500,000 in funding to expand their operations. Through our streamlined process, they were approved within 24 hours and received funding within 48 hours. This allowed them to hire new talent, expand their product offerings, and increase revenue by 250% in just 6 months.
              </Typography>
              <Typography variant="caption" className={classes.caseStudyStats}>
                Key Metrics: <br />
                - Funding Amount: $500,000 <br />
                - Revenue Growth: 250% <br />
                - Time to Funding: 48 hours <br />
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.caseStudyImage} />
          </Grid>
        </Grid>

        <Box textAlign="center" mt={4}>
          <Button variant="contained" className={classes.ctaButton}>
            Discover How We Can Help You
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default CaseStudySection;
