import { useState } from 'react';
import { ref, push } from 'firebase/database';
import { database } from '../firebase';

const useSubmitForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const submitForm = async (formData) => {
    setLoading(true);
    setError(null);

    try {
      // Reference to your Realtime Database 'applications' node
      const applicationsRef = ref(database, 'applications');

      // Add current date and time
      const dateSubmitted = new Date().toISOString(); // ISO string format for consistency

      // Push form data with dateSubmitted to Firebase
      await push(applicationsRef, { ...formData, dateSubmitted });

      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  return { submitForm, loading, error, success };
};

export default useSubmitForm;
