import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#121212', // Deep black background for a luxury feel
    padding: '60px 0', // Internal padding for content spacing
    margin: '0', // Remove any margin around the root
    minHeight: '100vh', // Ensure the page fills the full height of the viewport
    boxSizing: 'border-box', // Ensure padding and borders are included in width/height
    width: '100vw', // Ensure the page covers the full viewport width
    overflowX: 'hidden', // Prevent horizontal scrolling
  },
  headerBox: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  headerText: {
    fontSize: '3.5rem', // Larger for impact
    fontWeight: 'bold',
    color: '#FFD700', // Bright gold for the main heading
    textTransform: 'uppercase',
  },
  missionBox: {
    marginBottom: '30px',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#1F1F1F', // Slight contrast for each section
    borderRadius: '10px',
  },
  commitmentBox: {
    marginBottom: '30px',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#1F1F1F', // Slight contrast for each section
    borderRadius: '10px',
  },
  valuesBox: {
    marginBottom: '30px',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#1F1F1F', // Slight contrast for each section
    borderRadius: '10px',
  },
  contactBox: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#1F1F1F', // Slight contrast for each section
    borderRadius: '10px',
  },
  subtitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#FFFFFF', // White subtitle for contrast
    marginBottom: '15px',
    textTransform: 'uppercase',
  },
  bodyText: {
    fontSize: '1.2rem',
    lineHeight: '1.8',
    color: '#D4AF37', // Luxurious gold for text
    marginBottom: '20px',
    maxWidth: '800px', // Ensure content is not too wide
    margin: '0 auto', // Center-align the content
  },
  contactButton: {
    marginTop: '20px',
    backgroundColor: '#FFD700', // Bright gold for buttons
    color: '#121212', // Dark text for contrast
    fontWeight: 'bold',
    padding: '10px 30px',
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#B8860B', // Darker gold on hover
    },
  },
}));

export default useStyles;

