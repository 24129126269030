import React, { useState, useRef } from 'react';
import {
  Container,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import useStyles from '../styles/ApplicationFormStyles';
import PersonalInformationForm from './PersonalInformationForm';
import BusinessInformationForm from './BusinessInformationForm';
import useSubmitForm from '../hooks/useSubmitForm';

const ApplicationForm = () => {
  const classes = useStyles();
  const { submitForm, loading, error, success } = useSubmitForm();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [smsOptIn, setSmsOptIn] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [signature, setSignature] = useState(null);
  const sigCanvas = useRef(null);

  // Initialize all fields with empty strings to avoid null or undefined issues
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    companyName: '',
    businessAddress: '',
    businessCity: '',
    businessState: '',
    businessZipCode: '',
  });

  const [captchaNumbers] = useState({
    num1: Math.floor(Math.random() * 10),
    num2: Math.floor(Math.random() * 10),
  });
  const correctAnswer = captchaNumbers.num1 + captchaNumbers.num2;

  // Handle CAPTCHA validation
  const handleCaptchaChange = (event) => {
    setCaptchaValue(event.target.value);
    if (parseInt(event.target.value) === correctAnswer) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  // Handle signature save
  const saveSignature = () => {
    if (sigCanvas.current) {
      setSignature(sigCanvas.current.toDataURL());
    }
  };

  // Handle signature clear
  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignature(null);
    }
  };

  // Update form fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value || '', // Ensure non-null value
    });
  };

  // Improved validation logic
  const validateForm = () => {
    // Ensure all required fields are filled
    for (const key in formData) {
      if (!formData[key] || formData[key].trim() === '') {
        alert(`Please fill out the ${key} field.`);
        return false;
      }
    }

    if (!termsAccepted) {
      alert('Please accept the terms and conditions.');
      return false;
    }

    if (!captchaVerified) {
      alert('Please verify the CAPTCHA.');
      return false;
    }

    if (!signature) {
      alert('Please add your signature.');
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form before submitting
    if (!validateForm()) {
      return;
    }

    const fullFormData = {
      ...formData,
      signature,
      smsOptIn,
      captchaVerified,
    };

    await submitForm(fullFormData);
  };

  return (
    <Container maxWidth="md" className={classes.formContainer}>
      <Typography variant="h4" className={classes.mainTitle}>
        Get Started
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Complete this simple form and one of our funding experts will contact you in less than 24 hours.
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Personal Information Form */}
        <PersonalInformationForm
          classes={classes}
          formData={formData}
          handleInputChange={handleInputChange}
        />

        {/* Business Information Form */}
        <BusinessInformationForm
          classes={classes}
          formData={formData}
          handleInputChange={handleInputChange}
        />

        {/* SMS Opt-In */}
        <Typography variant="h5" className={classes.sectionTitle}>
          SMS Opt-in
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={smsOptIn}
                  onChange={() => setSmsOptIn(!smsOptIn)}
                  className={classes.checkbox}
                />
              }
              label="I agree to receive SMS notifications and consent to the use of my phone number."
            />
          </Grid>
        </Grid>

        {/* Terms and Conditions */}
        <Typography variant="h5" className={classes.sectionTitle}>
          Terms & Privacy
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  className={classes.checkbox}
                />
              }
              label="I have reviewed and agree to BlacTie Capital's Terms and Privacy Policy."
            />
          </Grid>
        </Grid>

        {/* Signature Section */}
        <Typography variant="h5" className={classes.sectionTitle}>
          Signature
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SignatureCanvas
              penColor="gold"
              canvasProps={{ width: 500, height: 200, className: classes.signatureCanvas }}
              ref={sigCanvas}
            />
            <div className={classes.signatureActions}>
              <Button variant="outlined" onClick={saveSignature} className={classes.signatureButton}>
                Save Signature
              </Button>
              <Button variant="outlined" onClick={clearSignature} className={classes.signatureButton}>
                Clear Signature
              </Button>
            </div>
          </Grid>
        </Grid>

        {/* Custom CAPTCHA */}
        <div className={classes.captchaBox}>
          <Typography variant="body1" className={classes.captchaText}>
            Solve the following equation: {captchaNumbers.num1} + {captchaNumbers.num2}
          </Typography>
          <TextField
            label="Your Answer"
            variant="outlined"
            fullWidth={false}
            className={classes.captchaInput}
            style={{ maxWidth: '150px' }}
            value={captchaValue}
            onChange={handleCaptchaChange}
          />
          {!captchaVerified && captchaValue !== '' && (
            <Typography variant="caption" className={classes.errorMessage}>
              Incorrect CAPTCHA answer. Please try again.
            </Typography>
          )}
        </div>

        {/* Submit Button */}
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            className={classes.submitButton}
            disabled={loading}
            type="submit"
          >
            {loading ? 'Submitting...' : 'Submit Application'}
          </Button>
        </Grid>

        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="primary">Form submitted successfully!</Typography>}
      </form>
    </Container>
  );
};

export default ApplicationForm;
