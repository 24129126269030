import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';

const useApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const applicationsRef = ref(database, 'applications');

    // Listen for real-time updates from Firebase
    const unsubscribe = onValue(
      applicationsRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const formattedApplications = Object.keys(data).map((key) => ({
            id: key,
            // Business details
            businessName: data[key].companyName || '',
            dbaName: data[key].dbaName || '',
            productsSold: data[key].productsSold || '',
            dateBusinessStarted: data[key].businessStartDate || '',
            businessAddress: data[key].businessAddress || '',
            cityStateZip: `${data[key].businessCity || ''}, ${data[key].businessState || ''}, ${data[key].businessZipCode || ''}`,
            legalEntity: data[key].businessType || '', 
            businessPhone: data[key].businessPhoneNumber || '',
            annualRevenue: data[key].annualRevenue || '',
            federalTaxID: data[key].federalTaxId || '',
            locationType: data[key].locationType || '',
            
            // Owner details
            ownerName: `${data[key].firstName || ''} ${data[key].lastName || ''}`,
            ownerAddress: data[key].address || '',
            ownerCityStateZip: `${data[key].city || ''}, ${data[key].state || ''}, ${data[key].zipCode || ''}`,
            ownerPhone: data[key].phoneNumber || '',
            ownerEmail: data[key].email || '',
            ownershipPercentage: data[key].ownershipPercentage || '',
            ownerDOB: data[key].dateOfBirth || '',
            ownerSSN: data[key].ssn || '',
            jobTitle: data[key].jobTitle || '',
            
            // Funding details
            amountRequested: data[key].amountRequested || '',
            previousCashAdvance: data[key].previousCashAdvance || '',
            dateOfFunding: data[key].dateOfFunding || '',
            balanceDue: data[key].balanceDue || '',
            
            // Additional information
            captchaVerified: data[key].captchaVerified || false,
            smsOptIn: data[key].smsOptIn || false,
            signature: data[key].signature || '',
            
            // Add dateSubmitted field
            dateSubmitted: data[key].dateSubmitted || '',
          }));
          console.log("Formatted applications:", formattedApplications); // Log the applications after formatting

          setApplications(formattedApplications);
        } else {
          setApplications([]);
        }
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  return { applications, loading, error };
};

export default useApplications;
